import { getAppRefNumber } from "./citi.flux";
import config from '../config';

const getCitiThankyouUrl = (callDuration, callData) => {
    const name = callData.users[0].name;
    const appRefNum = getAppRefNumber(callData.users[0].documents);
    const data = {
        appRefId: appRefNum,
        name,
        time: Math.ceil((callDuration || 60) / 60)
    }
    let url = config.host;
    if (callData.configuration.customDomain) {
        url = callData.configuration.customDomain + callData.configuration.customPath
    }
    return `${url}custom/citi/thankyou/?data=${window.btoa(JSON.stringify(data))}`;
}

const executeRedirection = (client, callDuration, callData) => {
    switch (client) {
        case "citi":
            return getCitiThankyouUrl(callDuration, callData);
        default:
            return callData.configuration.redirectOnSuccessfulURL;
    }
}

export {
    executeRedirection
}