<template>
  <div class="text-center">
    <p class="salutation">Sorry, your browser is incompatible!</p>

    <v-divider style="margin-top: 10vh;"></v-divider>
    <v-row justify="center">
      <v-col sm="10" cols="10" md="10" xl="10" lg="10">
        <p class="title mb-5">Our application is best suited in the following browsers</p>
        <img src="@/assets/browsers.svg" />
        <p class="subtitle-1 mt-5">Please open the link from any of these browsers via your email.</p>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  data: () => ({
    name: "",
    checkbox: false,
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
  }),
  created() {
    if (
      this.callData.users &&
      this.callData.users[0] &&
      this.callData.users[0].name
    ) {
      this.name = this.callData.users[0].name;
      if (this.name) {
        this.name = this.name[0].toUpperCase() + this.name.substr(1);
      }
    }
  },
  props: ["requestId", "callData"],
};
</script>

<style scoped>
.salutation {
  font-size: 6vh;
  color: black !important;
}
.instructionText {
  font-size: 16px;
}
.customBtn {
  width: 200px;
  color: white;
}
</style>