<template>
  <div class="text-center" v-if="allowLoading">
    <p class="salutation pb-4">Thanks, {{name}}! {{salutationText}} </p>

    <v-row v-if="defaultInstructionScheme" justify="center">
      <v-col sm="10" cols="10" md="8" xl="6" lg="6">
        <v-row justify="center" v-if="!isMobile">
          <v-col sm="10" cols="10" md="6" xl="6" lg="6" align="right">
            <img class="instructionImg" src="@/assets/pancard.svg" />
          </v-col>
          <v-col
            sm="10"
            cols="10"
            md="6"
            xl="6"
            lg="6"
            align="left"
            class="align-self-center instructionText"
          >Join the call and show your PAN as directed</v-col>
          <v-col
            sm="10"
            cols="10"
            md="6"
            xl="6"
            lg="6"
            align="right"
            class="align-self-center instructionText"
          >Follow the instructions till {{displayName}} official captures the required details</v-col>
          <v-col sm="10" cols="10" md="6" xl="6" lg="6" align="left">
            <img class="instructionImg" src="@/assets/instructions.svg" />
          </v-col>
          <v-col sm="10" cols="10" md="6" xl="6" lg="6" align="right">
            <img class="instructionImg" src="@/assets/interogation.svg" />
          </v-col>
          <v-col
            sm="10"
            cols="10"
            md="6"
            xl="6"
            lg="6"
            align="left"
            class="align-self-center instructionText"
          >Answer the questions asked by {{displayName}} official for verification purposes</v-col>
        </v-row>
        <v-row justify="center" align="center" v-else>
          <v-col cols="10">
            <img class="instructionImg" src="@/assets/pancard.svg" />
          </v-col>
          <v-col cols="10">Join the call and show your PAN as directed</v-col>
          <v-col cols="10">
            <img class="instructionImg" src="@/assets/instructions.svg" />
          </v-col>
          <v-col
            cols="10"
          >Follow the instructions till {{displayName}} official captures the required details</v-col>
          <v-col cols="10">
            <img class="instructionImg" src="@/assets/interogation.svg" />
          </v-col>
          <v-col
            cols="10"
          >Answer the questions asked by {{displayName}} official for verification purposes</v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-else class="text-left px-3 py-3" style="background-color: #EEEEEE">
    <p class="mt-1" align="center" style="font-size: 4vh; color: #333333 ; line-height: 1.2">
      {{instructionHeader}}
      <br />
    </p>
      <v-timeline align-top dense>
        <v-timeline-item
          v-for="(step, index) in callData.instructionPage.instructions"
          :key="`${index}-step`"
          fill-dot
          icon-color="signzy_blue"
        >
          <template v-slot:icon>
            <span style="color:white;">{{index+1}}</span>
          </template>
          <v-row v-if="(index+1) % 2 == 0" justify="center" align="center">
            <v-col>
              <div>
                <span class="title">{{step.title}}</span>
                <p class="single-inst">{{step.body}}</p>
              </div>
            </v-col>
            <v-col v-if="step.isImgRequired">
              <img :src="step.imgURL" :style="{maxHeight: '200px', maxWidth: isMobile ? '200px' :'350px'}"/>
            </v-col>
          </v-row>
          <v-row v-else justify="center" align="center">
            <v-col v-if="step.isImgRequired">
              <img :src="step.imgURL" :style="{maxHeight: '200px', maxWidth: isMobile ? '200px' :'350px'}"/>
            </v-col>
            <v-col>
              <div>
                <span class="title">{{step.title}}</span>
                <p class="single-inst">{{step.body}}</p>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </div>
    <div class="mt-3 mb-5">
      <v-checkbox
        v-if="callData.configuration.consentRequired ? true : false"
        v-model="checkbox"
        :label="callData.configuration.consentText"
      ></v-checkbox>
      <v-btn
        :disabled="(!checkbox && callData.configuration.consentRequired ? true : false)"
        @click="nextPage()"
        class="customBtn"
        :style="{
          backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || '#1365c0'}`,
          color:`${$store.getters.callConfig.buttonTextColor || null}`
        }"
      >Get Started</v-btn>
    </div>
  </div>
</template>

<script>
import { logReportData }  from  "@/Plugins/reportLogger.js";
import { mapActions } from "vuex";
import { endCustomer } from "@/assets/subStatus.json";
export default {
  data: () => ({
    name: "",
    instructionHeader:"",
    allowLoading: false,
    salutationText:"Your Video KYC session is about to begin.",
    currentStep: 1,
    checkbox: false,
    displayName: "Citi",
    defaultInstructionScheme: true,
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    logObject: endCustomer
  }),
  methods: {
    ...mapActions(["pageEntry", "sessionCleanUp", "customInitiatePageCleanup"]),
    nextPage(){
      if(this.callData.configuration.consentRequired ? true : false){
        let initiationId = sessionStorage.getItem('initiationId');
        let data = {}
        data.endUserConsent = true;
        if(initiationId){
          logReportData(this.socket, initiationId, data)
        } else {
          console.error("initiationId not found, unable to log endUserConsent");
        }
        sessionStorage.setItem("consentTimestamp", Date.now())
      }
      this.$router.replace({name:'prerequisite'})
    },
  },
  beforeRouteEnter(to,from,next) {
    if(sessionStorage.getItem("onPage") == "Instructions" && !sessionStorage.getItem("internalRefresh")){
      sessionStorage.setItem("InstructionsRefreshed", true);
      sessionStorage.setItem("triggerCustomMIS", true);
      sessionStorage.setItem("fromPage", "Instruction");
    }

    sessionStorage.getItem("internalRefresh") && sessionStorage.removeItem("internalRefresh")
    sessionStorage.getItem("adminLeft") && sessionStorage.removeItem("adminLeft")
    let path = from.path.toString().split('/').pop();
    if((path == "start" && !sessionStorage.getItem("StartVideoRefreshed")) || (path == "initiate" && !sessionStorage.getItem("InitiateQueueRefreshed")) ){
      if((path == "initiate" && !sessionStorage.getItem("InitiateQueueRefreshed"))){
        sessionStorage.setItem("customInitiatePageCleanup", true);
      }
      sessionStorage.setItem("internalRefresh", true);
      next();
      let timeout = setTimeout(()=>{
        clearInterval(timeout);
        location.reload();
      },50);
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("pageExitCleanUp", "Instructions");
    next();
  },
  beforeCreate(){
    if(sessionStorage.getItem("disableInstructionsPage") && !sessionStorage.getItem("errorPage")){
      this.$router.replace({ name: "prerequisite" });
    }
  },
  async created() {
    sessionStorage.getItem("PrerequisitesRefreshed") && sessionStorage.removeItem("PrerequisitesRefreshed");
    sessionStorage.getItem("vpnChecker") && sessionStorage.removeItem("vpnChecker");
    window.onbeforeunload = () => {
      if(sessionStorage.getItem('adminLeft') !== 'true' && !sessionStorage.getItem('internalRefresh')){
        try {
          let initiationId = sessionStorage.getItem("initiationId");
          let data = { ...this.logObject.instructionsPage.closed };
          data.currentProcess = "Customer closed the instructions Page";
          data.isCallActive = false;
          logReportData(this.socket, initiationId, data);
        } catch (err) {
          console.log(err);
        }
      }
    }
    if(!sessionStorage.getItem("disableInstructionsPage")){
      if(!sessionStorage.getItem("internalRefresh")){
        this.pageEntry("Instructions");
      }
      if (
        this.callData.users &&
        this.callData.users[0] &&
        this.callData.users[0].name
      ) {
        this.name = this.callData.users[0].name;
        if (this.name) {
          this.name = this.name[0].toUpperCase() + this.name.substr(1).toLowerCase();
          this.name = this.name.split(" ")[0];
        }
      }

      console.log("this.callData", this.callData)

      if (this.callData.instructionPage) {
        this.defaultInstructionScheme = this.callData.instructionPage.defaultInstructionScheme;
      }

      this.instructionHeader = this.callData.configuration.instructionHeader || "";
      if(this.callData.configuration.salutationTextRequired){
        this.salutationText = this.callData.configuration.salutationText 
      } 
      this.displayName = this.callData.configuration.displayName || "";

      if(!sessionStorage.getItem("disableInstructionsPage")){
        eventBus.$on("landing-page-mis-triggered", ()=>{
          try{
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {}; 
            data.onPage = "Instructions"
            data.currentProcess = "User was on instructions page";
            data.callId = this.requestId ? this.requestId : "";
            data.isCallActive = true;
            data.endUserConsent = false
            logReportData(this.socket,initiationId,data);
            // eventBus.$off("landing-page-mis-triggered");
            if(!sessionStorage.getItem("internalRefresh") && sessionStorage.getItem("customInitiatePageCleanup")){
              this.customInitiatePageCleanup();
            }
            eventBus.$emit("prev-session-logging-completed");
          } catch (error){
            console.log(error);
          }
        });

        eventBus.$on("multiple-session-detected", ()=>{
          //Turning off the existing eventBus since multiple session is triggered before the previous event
          eventBus.$off("landing-page-mis-triggered");
          eventBus.$on("landing-page-mis-triggered", ()=>{
            try{
              let initiationId = sessionStorage.getItem('initiationId');
              let data = { ...this.logObject.multipleSessions.dropped };              
              data.callId = this.requestId;
              data.isMultipleSession = true;
              data.currentProcess = "Multiple sessions detected";
              logReportData(this.socket, initiationId, data);
            }        
            catch(err){
              console.log("Error type:: socket reporting :::", err);
            }
          });
        });
      }

      if(!sessionStorage.getItem("internalRefresh")){
        this.sessionCleanUp();
      }

      this.allowLoading = true;
    }
  },
  props: ["requestId", "callData", "socket"],
};
</script>

<style scoped>
.salutation {
  font-size: 4vh;
  color: black !important;
  line-height: 1.2;
  width: 75%;
  margin: 0 auto;
}
.instructionText {
  font-size: 16px;
}
.customBtn {
  width: 200px;
  color: white;
}
.theme--light.v-timeline::before{
  background-color: #EEEEEE;
}
@media screen and (max-width: 480px) {
  .salutation{
    font-size: 3vh;
  }
  .single-inst{
    font-size: 18px !important;
  }
  .title{
    font-size: 18px !important;
  }
}
</style>