var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"salutation"},[_vm._v(_vm._s(_vm.instructionHeader[_vm.step]))]),_c('p',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.instructionSubHeader[_vm.step]))]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"12","cols":"12","md":"10","xl":"8","lg":"10"}},[_c('div',{staticClass:"feedback"},[_c('video',{attrs:{"id":"reflection","autoplay":"","playsinline":""}}),_c('div',{staticClass:"videoGrad",attrs:{"id":"videoGradient"}}),_c('div',{staticClass:"permissionProgress",attrs:{"id":"permissionBox"}},[_c('div',{staticClass:"progress pa-2 pb-2",staticStyle:{"margin":"0 auto"}},[(_vm.step == 'initiate')?_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"1%"},attrs:{"sm":"6","cols":"6","md":"6","xl":"6","lg":"6"}},[_c('b',[_vm._v("Position in queue")])]),(_vm.showETA)?_c('v-col',{staticStyle:{"padding-bottom":"1%"},attrs:{"sm":"6","cols":"6","md":"6","xl":"6","lg":"6"}},[_c('b',[_vm._v("Approximate wait time")])]):_vm._e()],1):_vm._e(),(_vm.step == 'initiate')?_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"1.5%","padding-bottom":"1.5%"},attrs:{"sm":"6","cols":"6","md":"6","xl":"6","lg":"6"}},[_c('div',{staticClass:"timewrapper",style:({
                    color: `${
                      _vm.$store.getters.callConfig.textColor || null
                    }!important`,
                  })},[_vm._v(" "+_vm._s(_vm.numPeople)+" ")])]),(_vm.showETA)?_c('v-col',{staticStyle:{"padding-top":"1.5%","padding-bottom":"1.5%"},attrs:{"sm":"6","cols":"6","md":"6","xl":"6","lg":"6"}},[(_vm.eta)?_c('div',{staticClass:"timewrapper",style:({
                    color: `${
                      _vm.$store.getters.callConfig.textColor || null
                    }!important`,
                  })},[_vm._v(" "+_vm._s(_vm.eta)+" Minute(s) ")]):_c('div',{staticClass:"timewrapper",style:({
                    color: `${
                      _vm.$store.getters.callConfig.textColor || null
                    }!important`,
                  })},[_vm._v(" Loading.. ")])]):_vm._e()],1):(_vm.step == 'picked')?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6","cols":"12","md":"6","xl":"6","lg":"6"}},[_c('b',[_vm._v("Approximate wait time")]),(_vm.ringerSeconds)?_c('div',{staticClass:"timewrapper",style:({
                    color: `${
                      _vm.$store.getters.callConfig.textColor || null
                    }!important`,
                  })},[_vm._v(" "+_vm._s(_vm.ringerSeconds)+" ")]):_vm._e()])],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6","cols":"12","md":"6","xl":"6","lg":"6"}},[_c('b',{staticClass:"mb-2",staticStyle:{"display":"block"}},[_vm._v("Unable to connect you to a "+_vm._s(_vm.displayName)+" representative.")]),_c('div',{staticClass:"timewrapper",on:{"click":function($event){return _vm.workOnNewCall(true)}}},[_vm._v("Retry")])])],1)],1),_c('span',{staticClass:"pb-2",staticStyle:{"display":"block"}},[_vm._v("Wait time may vary as per the availability of the "+_vm._s(_vm.displayName)+" representatives")]),(_vm.numPeople > 3)?_c('p',{staticStyle:{"color":"#ffe200","font-size":"12px"}},[_vm._v(" We are experiencing a high volume of Video KYC sessions today. Waiting time might be longer than usual. ")]):_vm._e()])])])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"550"},model:{value:(_vm.showNoUserAvailable),callback:function ($$v) {_vm.showNoUserAvailable=$$v},expression:"showNoUserAvailable"}},[_c('v-card',[(_vm.isCiti)?_c('v-card-text',{staticClass:"text-center pa-5"},[_c('p',{staticClass:"subtitle-2"},[_vm._v(" Sorry! We're unable to connect you to a Citibanker at this time."),_c('br'),_c('br'),_vm._v(" You can set an appointment or try again later, per the timings below,on any working day."),_c('br'),_vm._v(" 9am to 9pm for Credit Cards, and 9am to 6pm for Banking Accounts ")])]):_c('v-card-text',{staticClass:"text-center pa-5"},[_c('img',{attrs:{"src":require("@/assets/representativeBusy.png")}}),_c('p',{staticClass:"subtitle-2"},[_vm._v(" No "+_vm._s(_vm.displayName)+" representative available right now! ")])]),_c('v-card-actions',{staticClass:"pt-0 mr-2 pb-3"},[_c('v-spacer'),_c('v-btn',{style:({
            backgroundColor: `${
              _vm.$store.getters.callConfig.buttonOutlineColor || null
            }!important`,
          }),attrs:{"small":"","dark":"","color":"signzy_blue"},on:{"click":function($event){return _vm.tryAgain()}}},[_vm._v("Try again")]),(_vm.showReschedule)?_c('v-btn',{attrs:{"small":"","dark":"","color":"success"},on:{"click":function($event){return _vm.reschedule()}}},[_vm._v("Schedule Call")]):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"550"},model:{value:(_vm.showWorkingDayModal),callback:function ($$v) {_vm.showWorkingDayModal=$$v},expression:"showWorkingDayModal"}},[(!_vm.workingDay)?_c('v-card',[_c('v-card-text',{staticClass:"text-center pa-5"},[_c('img',{attrs:{"src":require("@/assets/representativeBusy.png")}}),_c('p',{staticClass:"subtitle-2"},[_vm._v(" Please connect with us during business working hours from "+_vm._s(this.workingHourStarts)+" to "+_vm._s(this.workingHourEnds)+" ")])]),_c('v-card-actions',{staticClass:"pt-0 mr-2 pb-3"},[_c('v-spacer'),_c('v-btn',{style:({
            backgroundColor: `${
              _vm.$store.getters.callConfig.buttonOutlineColor || null
            }!important`,
          }),attrs:{"small":"","dark":"","color":"signzy_blue"},on:{"click":function($event){return _vm.tryAgain()}}},[_vm._v("Try again")]),(_vm.showReschedule)?_c('v-btn',{attrs:{"small":"","dark":"","color":"success"},on:{"click":function($event){return _vm.reschedule()}}},[_vm._v("Schedule Call")]):_vm._e()],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"550"},model:{value:(_vm.showSomethingWentWrong),callback:function ($$v) {_vm.showSomethingWentWrong=$$v},expression:"showSomethingWentWrong"}},[_c('v-card',[_c('v-card-text',{staticClass:"text-center pa-5"},[_c('img',{attrs:{"src":require("@/assets/representativeBusy.png")}}),_c('p',{staticClass:"subtitle-2"},[_vm._v("Something went wrong")])]),_c('v-card-actions',{staticClass:"pt-0 mr-2 pb-3"},[_c('v-spacer'),(_vm.pollingFailure >= 10 || _vm.showTryAgain)?_c('v-btn',{style:({
            backgroundColor: `${
              _vm.$store.getters.callConfig.buttonOutlineColor || null
            }!important`,
          }),attrs:{"small":"","dark":"","color":"signzy_blue"},on:{"click":function($event){return _vm.tryAgain()}}},[_vm._v("Try again")]):_vm._e(),(_vm.showReschedule)?_c('v-btn',{attrs:{"small":"","dark":"","color":"success"},on:{"click":function($event){return _vm.reschedule()}}},[_vm._v("Schedule Call")]):_vm._e()],1)],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-alert',{staticClass:"mt-3 mb-3",staticStyle:{"white-space":"pre-line"},attrs:{"value":_vm.priorityUserJoined,"dense":"","dismissible":"","type":"warning","transition":"scroll-x-transition","max-width":_vm.messageBoxWidth}},[_vm._v(" Your queue position has been adjusted slightly. Thanks for your understanding! ")])],1),(_vm.showReschedule)?_c('div',{staticClass:"text-center mt-3 mb-5"},[_c('a',{on:{"click":function($event){return _vm.showRescheduleWarning()}}},[_vm._v("Click here")]),_vm._v(" if you want to reschedule your Video KYC sometime later. "),(_vm.isFailure)?_c('v-btn',{staticClass:"customBtn",attrs:{"dark":"","color":"warning"},on:{"click":function($event){return _vm.startProcess()}}},[_vm._v("Retry")]):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"550"},model:{value:(_vm.rescheduleWarning),callback:function ($$v) {_vm.rescheduleWarning=$$v},expression:"rescheduleWarning"}},[_c('v-card',[_c('v-row',{staticClass:"ma-0 pa-3",staticStyle:{"background-color":"#fcfcfc","border-radius":"8px"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticStyle:{"padding-left":"4%","padding-right":"4%"},attrs:{"align":"center"}},[_c('img',{staticClass:"pb-3",staticStyle:{"width":"75px"},attrs:{"src":require("@/assets/error.svg")}}),_c('p',{staticClass:"mb-4",style:({
              color: _vm.$store.getters.callConfig.textColor || '#0065A3',
              fontSize: '18px',
              fontWeight: '600',
            })},[_vm._v(" You will be removed from the waiting queue if you choose to reschedule. ")]),(_vm.rescheduleDuration)?_c('p',{staticStyle:{"color":"#0f0f0f","font-size":"13px","letter-spacing":"0px","font-weight":"500"}},[_vm._v(" Note: You can only reschedule to upto "+_vm._s(_vm.rescheduleDuration)+" days from now. ")]):_vm._e(),_c('v-row',{staticClass:"mx-0 py-2",staticStyle:{"width":"100%"},attrs:{"justify":"center"}},[_c('div',{staticStyle:{"max-width":"100%","margin":"3%"}},[_c('v-btn',{staticClass:"px-4 py-4",style:({
                  backgroundColor: `${
                    _vm.$store.getters.callConfig.buttonOutlineColor || null
                  }!important`,
                  color: `${
                    _vm.$store.getters.callConfig.buttonTextColor || null
                  }!important`,
                }),attrs:{"small":"","color":"primary","light":""},on:{"click":function($event){return _vm.showRescheduleWarning()}}},[_vm._v("Go Back")])],1),_c('div',{staticStyle:{"max-width":"100%","margin":"3%"}},[_c('v-btn',{staticClass:"px-4 py-4",style:({
                  color: `${
                    _vm.$store.getters.callConfig.buttonOutlineColor || null
                  }!important`,
                  borderColor: `${
                    this.$store.getters.callConfig.buttonOutlineColor || null
                  }!important`,
                }),attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.reschedule()}}},[_vm._v("Reschedule ")])],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }