<template>
  <div class="container">
    <p class="message">Looks like you’re using a VPN / Proxy Server.<br/> After successfully disconnecting from VPN, please press try again.</p>
    <div class="timer-display">{{ timerMessage }}</div>
    <button :disabled="timer > 0" @click="tryAgain" class="try-again-button">
      Try Again
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: 0,
    };
  },
  computed: {
    timerMessage() {
      let minutes = Math.floor(this.timer / 60);
      let seconds = this.timer % 60;
      return this.timer > 0 ? `${minutes}:${seconds.toString().padStart(2, '0')} left`: null ;
    },
  },
  methods: {
    startTimer() {
      this.timer = 120;
      const intervalId = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(intervalId);
        }
      }, 1000);
    },
    
    tryAgain() {
      let url = sessionStorage.getItem('callAgainHelper');
      if(sessionStorage.getItem("vpnChecker")){
        sessionStorage.setItem("vpnChecker", 0);
      }
      window.location.replace(url);
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.message {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px; 
  padding: 0 20px; 
  text-align: center;
  font-size: 1.5em;
}

.timer-display {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.try-again-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.try-again-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>

