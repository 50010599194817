import { vcip_end_points } from "./../config";

const getTranslatedInstruction = async (instructions, language) => {
    try {
        const translatedInstruction = (await axiosInstance.post(vcip_end_points.translate_instruction, {
            instructions,
            language
        })).data;
        for (let i = 0; i < instructions.length; i++) {
            if (!instructions[i]["translations"]) {
                instructions[i]["translations"] = {}
            }
            instructions[i]["translations"][language] = translatedInstruction[i];
        }
        return instructions;
    } catch (error) {
        console.log("Problme in transalation", error);
        return instructions;
    }
}

export {
    getTranslatedInstruction
}