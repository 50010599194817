import Fuse from 'fuse.js';
import { vcip_end_points } from "../config";
const blackListedDevices = async(deviceData) => {
    let blacklist = [];
    try {
        let responseList = (
            await axiosInstance.get(vcip_end_points.black_list_device)
        ).data;
        console.log(responseList)
        for (let i = 0; i < responseList.length; i++) {
            blacklist.push(responseList[i].complete_device_name);
        }
        console.log(blacklist)
        const options = {
            threshold: 0.3,
            includeScore:true,
            shouldSort: true
        }
        const fuse = new Fuse(blacklist, options);
            const result = fuse.search(deviceData.complete_device_name).filter(item => item.score < 0.2);
            console.log(result);
            return result.length > 0;
    } catch (err) {
        console.log("Some error occured in fetching black list devices:::::::::", err);
        // If failed to pull device list , allow the current device to proceed.
        return false;
    }
}
export {
    blackListedDevices as isBlackListedDevice
}