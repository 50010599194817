import { vcip_end_points } from "@/config";
import { logReportData } from "@/Plugins/reportLogger.js";
import { encrypt, decrypt } from '../src/flux/encdec.flux'
import { userDataStore as store } from './Plugins/UserData.Store.js'

import axiosLib from "axios";


let isUserInactive = false;
let inactiveTimer = null;
let inactiveTimeout = 120000; // 2 mins

const MISMiddleware = {

    isVPNConnected: async (requestId) => {
        let ipData = await axiosLib.get(store.getters.envValues?.ipFinder || vcip_end_points.ipFinder);
        if (!ipData.data) {
            throw new Error("Forencis error")
        }
        let encryptedIPAddress = encrypt(ipData.data.ip_addr, store.getters.envValues.otpEncKey)
        let encryForensicsData = (await axiosLib.post(vcip_end_points.forensics, { ip: encryptedIPAddress, checkProbe:!store.getters.disableVPNCheck , checkInfo:store.getters.enableIpInfo }))
        let decryptedData = decrypt(encryForensicsData.data, store.getters.envValues.otpEncKey);
        let forensicsData;
        try {
            forensicsData = JSON.parse(decryptedData)
        } catch (err) {
            console.log("forensics JSON error", err)
        }
        try {
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {};
            data.forensics = forensicsData.data;
            data.currentProcess = "Vpn Forensics passed";
            data.callId = requestId ? requestId : "";
            logReportData(this.socket, initiationId, data);
        } catch (err) {
            console.log(err);
        }
        return forensicsData;
    },
    onUserInactivity: {
        resetInactiveTimer: () => {
            clearTimeout(inactiveTimer);
            isUserInactive = false;
            inactiveTimer = setTimeout(() => {
                isUserInactive = true;
            }, inactiveTimeout);
        },
        setupInactiveDetection: () => {
            MISMiddleware.onUserInactivity.resetInactiveTimer();
            window.addEventListener("mousemove", MISMiddleware.onUserInactivity.resetInactiveTimer);
            window.addEventListener("keypress", MISMiddleware.onUserInactivity.resetInactiveTimer);
        },

        cleanupInactiveDetection: () => {
            window.removeEventListener("mousemove", MISMiddleware.onUserInactivity.resetInactiveTimer);
            window.removeEventListener("keypress", MISMiddleware.onUserInactivity.resetInactiveTimer);
            clearTimeout(inactiveTimer);
        },
    },
    onUserOnline: {
        isUserOnline() {
            return navigator.onLine;
        },

        monitorConnectionStatus(callback) {
            window.addEventListener('online', () => {
                callback(true);
            });

            window.addEventListener('offline', () => {
                callback(false);
            });
        }
    },
    OnTabSwitch: {
        isTabVisible() {
            return !document.hidden;
        },
        monitorTabVisibility(callback) {
            document.addEventListener('visibilitychange', () => {
                callback(MISMiddleware.OnTabSwitch.isTabVisible());
            });
        }
    }
};

export { isUserInactive, MISMiddleware };


//example of isVPNConnected in vue component
//  let forensicsData=await MISMiddleware.isVPNConnected(this.requestId);

// sample response of forensicsData
// {
//     "status": "success",
//     "data": {
//         "ip": "106.51.73.207",
//         "country_code": "IN",
//         "country_name": "INDIA",
//         "city": "BENGALURU",
//         "block": 0, 
//         "zipCode": "560027",
//         "timezone": "IST, Asia/Kolkata",
//         "state": "KARNATAKA",
//         "stateCode": "KA"
//     }
// }

// if(forensicsData.block==0) // vpn not connected


//example of user online in vue component

// import { MISMiddleware } from "[Filepath]";
// export default {
//     data() {
//         return {
//             online: MISMiddleware.onUserOnline.isUserOnline(),
//         };
//     },

//     MISMiddleware.onUserOnline.monitorConnectionStatus((isOnline) => {
//         this.online = isOnline;
//         if (isOnline) {
//             console.log('User is online');
//         } else {
//             console.log('User is offline');
//         }
//     });
// }
// }


//example of tab switch in vue component

// import { MISMiddleware} from "../../MISMiddleware";
// export default {
//     data() {
//         return {
//             tabVisible: MISMiddleware.OnTabSwitch.isTabVisible()
//         };
//     },
//     mounted() {
//         MISMiddleware.OnTabSwitch.monitorTabVisibility((isVisible) => {
//             this.tabVisible = isVisible;
//             if (isVisible) {
//                 console.log('Tab is visible');
//             } else {
//                 console.log('Tab is hidden');
//             }
//         });
//     }
// }

//example of user inactivity in vue component

// import { isUserInactive, MISMiddleware} from "../../MISMiddleware";

// export default {
//   mounted() {
//     MISMiddleware.onUserInactivity.setupInactiveDetection();
//   },
//   beforeDestroy() {
//     MISMiddleware.onUserInactivity.cleanupInactiveDetection();
//   },
//   computed: {
//     isUserInactive() {
//       return isUserInactive;
//     },
//   },
// };