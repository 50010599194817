<template>
  <v-card class="elevation-0">
    <v-card-text>
      <div class="text-center mt-3 mb-5">
        <p class="title">Would you like to complete the process now?</p>
        <p class="subheading">Connect with our representative to complete the Video KYC process.</p>
        <v-btn
          @click="$router.push({name:'instructions'})"
          class="customBtn mb-2"
          color="primary"
        >WAIT</v-btn>
        <p
          class="caption"
        >Node: You are subject to a waiting time depending on the availability of representatives</p>
        <v-divider class="mt-4 mb-4 ml-5 mr-5"></v-divider>
        <p class="title">Complete the process at your preferred time</p>
        <p class="subheading">Schedule an appointment with our representative at your convenience.</p>
        <v-btn outlined @click="reschedule()" class="customBtn mb-2" color="primary">Schedule</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    reschedule() {
      this.$router.push({
        name: "reschedule",
        params: { incoming: "waiting" }
      });
    }
  },
  created() {},
  props: ["requestId", "callData"]
};
</script>

<style scoped>
.customBorder {
  border: #d2eaf6 solid 0.5px;
  border-radius: 5px;
}
.customBtn {
  width: 200px;
}
.timeHandler {
  color: #0089cc;
}
</style>