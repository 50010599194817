<template>
  <v-content class="landingBackground ma-0 pa-0" fluid>
    <v-row justify="center" class="ma-0 pa-0">
      <v-col cols="12" sm="12" md="10" lg="8" xl="6" class="ma-0 pa-0">
        <v-card class="vcipCard elevation-0">
          <!-- <v-card-title v-if="$route.name!='startVideo'" class="vcipTitle">{{processLabel}}</v-card-title> -->
          <v-card-text>
            <div class="text-center">
              <p class="salutation">Your have successfully completed video KYC</p>
              <span class="body-2">Your application will be reviewed by our team.</span>
              <v-divider class="ma-5"></v-divider>
              <p class="feedback" :style="{color: $store.getters.callConfig.textColor || null}">Your feedback is valuable to us</p>
              <div v-if="!isFeedbackDone">
                <p>How would you rate your experience?</p>
                <v-rating half-increments hover v-model="rating" :color="$store.getters.callConfig.textColor || null" :background-color="$store.getters.callConfig.textColor || null"></v-rating>
                <div class="feedbackTextCont">
                  <v-text-field class="feedbackText" v-model="feedbackText" label="Any messages ?"></v-text-field>
                </div>
                <v-btn 
                @click="collectData()" 
                class="customBtn" 
                dark 
                color="signzy_blue" 
                :style="{
                  backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || '#1365c0'}!important`,
                  color:`${$store.getters.callConfig.buttonTextColor || null}!important`
                }"
                >Submit</v-btn>
              </div>
              <div v-if="isFeedbackDone">
                <p>Thankyou for the feedback!</p>
              </div>
            </div>
          </v-card-text>
          <!-- <v-card-actions v-if="!hideBranding && $route.name!='startVideo'" class="float-right">
            <img style="width:150px;" src="@/assets/signzy-logo-secured.png" />
          </v-card-actions>-->
        </v-card>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import { vcip_end_points } from "@/config";
export default {
  data: () => ({
    feedbackText: "",
    rating: 0,
    isFeedbackDone: false
  }),
  methods: {
    async collectData() {
      let selfIns = this;
      let response = await axiosInstance.post(vcip_end_points.feedback, {
        sessionId: this.$store.getters.endUserData["sessionId"],
        email: this.$store.getters.endUserData["email"],
        feedback: selfIns.rating,
        feedbackMessage: selfIns.feedbackText
      });
      store.flush();
      if (response.status == 200) {
        selfIns.isFeedbackDone = true;
        //window.parent.postMessage("videoUploadingDone", "*");
        window.parent.postMessage({ message: "videoUploadingDone" }, "*");
        eventBus.$emit("vueSnack", "Feedback submitted successfully!");
      } else {
        selfIns.isFeedbackDone = false;
        eventBus.$emit("vueSnack", "Something went wrong!");
      }
    }
  },
  created() {}
};
</script>

<style scoped>
.timewrapper {
  border: 1px white solid;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  margin-top: 10px;
}
.salutation {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 4vh;
  line-height: 1em;
  color: black !important;
}
.instructionText {
  font-size: 16px;
}
.feedback {
  color: #1365c0;
  font-size: 3vh;
  margin-top: 10px;
  margin-bottom: 10px;
}
.permissionProgress {
  position: absolute;
  color: white;
  padding-bottom: 20px;
}
.videoGrad {
  background: transparent
    linear-gradient(180deg, #00000000 0%, #00000004 26%, #000000 100%) 0% 0%
    no-repeat padding-box;
}
#videoGradient {
  position: fixed;
}
#reflection {
  width: 100%;
  background: black;
}
.customBtn {
  color: white;
  width: 200px;
}
.vcipCard {
  margin-top: 8vh;
  color: black !important;
}
.vcipTitle {
  background: #eff4fc;
  color: #2f75c7;
}
.feedbackText {
  width: 100vh;
  max-width: 400px;
}
.feedbackTextCont {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>